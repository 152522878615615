import { IEvent } from '../Admin/Events/events.types'
import http, { handleHttpError, handleHttpResponse } from '../../services/http'
import { HTTPResponse, IPaginationResponse } from '../../services/http/http.types'
import { IProject, IProjectComment, IProjectMedia } from './projects.types'
import { ITeam } from '../Teams/teams.types'
import { IProjectTechnogy } from 'screens/Admin/ProjectTechnologies/projectTechnologies.types'
import { IUser } from 'screens/Admin/RolesAndUsers/users.types'
import { IOrganization } from 'screens/Admin/Organizations/organizations.types'
import eventBus from 'services/eventBus'

interface IGetParams {
  search?: string
  eventId?: IEvent['id']
  nextPage?: string
  limit?: number
}
const getProjects = async (params: IGetParams): Promise<HTTPResponse<IPaginationResponse<IProject>>> => {
  return http.get('/nsi/v1/projects', { params }).then(handleHttpResponse).catch(handleHttpError)
}

export interface ICreateProjectDTO {
  techStack: string
  expectingResults: string
  motivation: string
  repositoryLink: string
  taskTrackingLink: string
  description: string
  name: string
  eventId: IEvent['id']
  teams: Array<ITeam['id']>
  keyTechnologies: Array<IProjectTechnogy['id']>
  mentorId?: IUser['id']
  organizationId?: IOrganization['id']
  teamsTargetCount?: number
  minEducationYear?: number
  maxEducationYear?: number
}

const createProject = async (dto: ICreateProjectDTO): Promise<HTTPResponse<IProject>> => {
  return http.post('/nsi/v1/projects', dto).then(handleHttpResponse).catch(handleHttpError)
}

const updateProject = async (
  id: IProject['id'],
  updates: Partial<ICreateProjectDTO>
): Promise<HTTPResponse<IProject>> => {
  return http
    .patch('/nsi/v1/projects/' + id, updates)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const updateProjectImage = async (id: IProject['id'], file: File) => {
  const formData = new FormData()
  formData.set('image', file)

  return http.put(`/nsi/v1/projects/${id}/image`, formData).then(handleHttpResponse).catch(handleHttpError)
}

const getProject = async (id: IProject['id']) => {
  return http
    .get('/nsi/v1/projects/' + id)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const approveProject = async (id: IProject['id']): Promise<HTTPResponse<any>> => {
  return http
    .post('/nsi/v1/projects/' + id + '/approve')
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const rejectProject = async (id: IProject['id']): Promise<HTTPResponse<any>> => {
  return http
    .post('/nsi/v1/projects/' + id + '/reject')
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const addProjectComment = async (
  projectId: IProject['id'],
  message: string
): Promise<HTTPResponse<IProjectComment>> => {
  return http.post(`/nsi/v1/projects/${projectId}/comment`, { message }).then(handleHttpResponse).catch(handleHttpError)
}

const joinProject = async (projectId: IProject['id']): Promise<HTTPResponse<IProject>> => {
  return http
    .post(`/nsi/v1/projects/${projectId}/join`)
    .then(handleHttpResponse)
    .catch(() => {
      eventBus.pub('http.error', { message: 'Места закончились', code: 500 })

      return { status: 'error', message: '', code: 500 }
    })
}

const setTeams = async (projectId: IProject['id'], teams: Array<ITeam['id']>): Promise<HTTPResponse<IProject>> => {
  return http
    .put('/nsi/v1/projects/' + projectId + '/set-teams', { teams })
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const importProjects = (file: File, eventId: IEvent['id']) => {
  const formData = new FormData()
  formData.set('file', file)
  formData.set('eventId', eventId.toString())

  return http.post('/nsi/v1/projects/import/csv', formData).then(handleHttpResponse).catch(handleHttpError)
}

const deleteAllProjects = () => {
  return http.delete('/nsi/v1/projects/admin/all').then(handleHttpResponse).catch(handleHttpError)
}

const getProjectMedia = (projectId: IProject['id']): Promise<HTTPResponse<IProjectMedia[]>> => {
  return http.get(`/nsi/v1/documents/project-media/${projectId}/list`).then(handleHttpResponse).catch(handleHttpError)
}

const createProjectMediaFiles = (projectId: IProject['id'], files: File[]): Promise<HTTPResponse<IProjectMedia[]>> => {
  const formData = new FormData()
  for (const file of files) {
    formData.append('files', file)
  }

  return http
    .post('/nsi/v1/documents/project-media/' + projectId, formData)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const deleteProjectMediaFile = (documentId: IProjectMedia['document']['id']) => {
  return http
    .delete('/nsi/v1/documents/project-files/' + documentId)
    .then(handleHttpResponse)
    .catch(handleHttpError)
}

const projectsApi = {
  getProjects,
  createProject,
  updateProject,
  updateProjectImage,
  getProject,
  approveProject,
  rejectProject,
  addProjectComment,
  joinProject,
  setTeams,
  importProjects,
  deleteAllProjects,
  getProjectMedia,
  createProjectMediaFiles,
  deleteProjectMediaFile,
}
export default projectsApi
