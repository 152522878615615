import { useRecoilValue } from 'recoil'
import { ITeam } from '../teams.types'
import selfProjectsAtom from 'screens/Auth/components/InitProvider/selfProjects.atom'
import eventsSelectors from 'screens/Admin/Events/events.selectors'

const useIsTeamLeavePossible = (id: ITeam['id']) => {
  const eventsMap = useRecoilValue(eventsSelectors.eventsFullMap)
  const selfProjects = useRecoilValue(selfProjectsAtom).items

  if (!id) return false
  const project = selfProjects.find((p) => p.projectToTeams?.some((t) => t.teamId === id))

  if (!project) return true

  const projectEvent = eventsMap[project.eventId]
  if (!projectEvent) return true

  const isEventOnGoing = new Date(projectEvent.endsAt).getTime() > new Date().getTime()
  if (!isEventOnGoing) return true

  return !eventsMap[project.eventId].locked
}

export default useIsTeamLeavePossible
